import React from 'react';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/core';
import { DatePicker } from 'antd';

export const StyledDateRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
  height: 40px !important;
  font-size: 16px;
  line-height: 40px;
`;

export const StyledDateRangePickerMobile = props => (
  <ClassNames>
    {({ css }) => (
      <StyledDateRangePicker
        {...props}
        popupStyle={{ width: '85vw' }}
        dropdownClassName={css`
          div {
            div {
              overflow-x: scroll;
            }
          }
        `}
      />
    )}
  </ClassNames>
);
