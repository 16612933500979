import React from 'react';
import PropTypes from 'prop-types';
import { Input as AntdInput } from 'antd';

import { MaxLengthDisplay } from './Input.styles';

const InputComponent = ({ type, size, ...props }) => {
  if (type === 'textarea') {
    return <AntdInput.TextArea {...props} />;
  } else {
    return <AntdInput type={type} size={size} {...props} />;
  }
};

const Input = ({
  type,
  placeholder,
  prefix,
  autoComplete,
  maxLength,
  size,
  value,

  isDisabled,

  onChange,
  updateFormValue,

  ...props
}) => {
  const handleOnChange = e => {
    const value = e.target.value;
    onChange(value);
    updateFormValue(value);
  };

  return (
    <>
      {!isNaN(maxLength) && (
        <MaxLengthDisplay>
          Max {maxLength} letters, {maxLength - String(value || '').length} letters left
        </MaxLengthDisplay>
      )}
      <InputComponent
        type={type}
        value={value}
        autoComplete={autoComplete}
        placeholder={placeholder}
        prefix={prefix}
        disabled={isDisabled}
        maxLength={maxLength}
        size={size}
        onChange={handleOnChange}
        {...props}
      />
    </>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  prefix: PropTypes.node,
  autoComplete: PropTypes.string,
  isDisabled: PropTypes.bool,
  maxLength: PropTypes.number,
  size: PropTypes.oneOf(['large', 'middle', 'small']),
  onChange: PropTypes.func,
  updateFormValue: PropTypes.func
};

Input.defaultProps = {
  type: undefined,
  placeholder: '',
  prefix: undefined,
  autoComplete: undefined,
  isDisabled: false,
  size: 'middle',
  onChange: () => {},
  updateFormValue: () => {}
};

export default Input;
