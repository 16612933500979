import styled from '@emotion/styled';
import { Select } from 'antd';

export const StyledFormSelection = styled(Select)`
  div {
    align-items: center;
    min-height: 40px !important;
    font-size: 16px;
  }
`;
